import { handleResetPaginationActive } from "@/util/common";
import React from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "./FaqContentsList.scss";

const FaqContentsList = ({
  faqType,
  searchTitle,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">게시판관리 - FAQ</div>
      </div>
      <div className="page-contents">
        <div className="faq-contents-list__search-form mb-20">
          <select
            className="select-box mr-20"
            id="faqType"
            name="faqType"
            value={faqType}
            onChange={onChangeFieldValue}
          >
            <option value="">전체</option>
            <option value={1}>일반</option>
            <option value={2}>가입/인증/탈퇴</option>
            <option value={3}>로그인/계정</option>
            <option value={4}>서비스 이용</option>
            <option value={5}>결제/충전</option>
          </select>

          <div className="input-chaining">
            <input
              className={"form-control"}
              type="text"
              name="searchTitle"
              placeholder="검색할 제목을 입력해주세요."
              value={searchTitle}
              onChange={onChangeFieldValue}
            />
            <div className="append">
              <button
                id="search"
                className={"btn btn-s h-full border-0"}
                type="button"
                onClick={() => {
                  onFetchPage(1);
                  handleResetPaginationActive();
                }}
              >
                조회
              </button>
            </div>
          </div>
          <button className={`btn btn-s`}>
            <Link to="/board/view/createFaqContents">FAQ 등록</Link>
          </button>
        </div>
        <div className="table-pagination-container ">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>유형</th>
                  <th>내용</th>
                  <th>작성자</th>
                  <th>작성일</th>
                  <th>게시여부</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={5}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`faqContents-tbody-${i}`}>
                      <td>{post.faq_type_name}</td>
                      <td className="text-align-start">
                        <Link
                          to={`/board/faqContents/${post.board_contents_idx}`}
                          className="text-kk text-bold font-size-15"
                        >
                          {post.title}
                        </Link>
                      </td>
                      <td>{post.reg_user_name}</td>
                      <td>{post.reg_dttm}</td>
                      <td>{post.publish_yn}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqContentsList;
